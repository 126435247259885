import React, { useEffect } from 'react';
import { notification } from 'antd';
import { onMessage, getMessaging } from "firebase/messaging";

const NotificationProvider = ({ children }) => {
    const [notificationApi, contextHolderNotification] = notification.useNotification();

   /*  useEffect(() => {
        const messaging = getMessaging();
        const unsubscribe = onMessage(messaging, (payload) => {
          const { title, body } = payload.notification;
          
          notificationApi.open({
            message: title,
            description: body,
            placement : 'top',
            duration : 3,
            pauseOnHover : true
          });
        }); 
        
        return () => unsubscribe();

    }, [notificationApi]); 
 */



    return (
        <>
            {contextHolderNotification}
            {children}
        </>
    )
};

export default NotificationProvider;