import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useIsLoggedIn } from "../hooks/ReduxHooks";

const RequireAuth = () => {
  const userFound = useIsLoggedIn();
  const location = useLocation();

  return userFound.logged ? (
    <Outlet />
  ) : (
    <Navigate to="/Singin" state={{ from: location }} replace />
  );
};

export default RequireAuth;
