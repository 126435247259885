import axios from 'axios';
import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { isLogged } from '../reducers/slices/isLoggedIn.slice';



const AxiosInterceptor = ({ children }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();


    useEffect(() => {

        const interceptorResponse = axios.interceptors.response.use((response) => {

            return response;

        }, function (error) {
            const { response } = error;

            if (response.status === 401) {

                const stateAuth = {
                    logged: false,
                    user: null,
                };
                dispatch(isLogged(stateAuth));
                navigate('/Singin', { state: { from: location }, replace: true })
            }

            if (response.status === 403) {
                navigate('/Unauthorized', { replace: true });
            }




            return Promise.reject(error);
        });
        return () => {
            axios.interceptors.response.eject(interceptorResponse);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate])

    return children;

}



export { AxiosInterceptor }