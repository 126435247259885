import { createSlice } from '@reduxjs/toolkit';


let initialState = {

}



export const singupErrors = createSlice(
  {
    name: 'singupErrors',
    initialState,
    reducers: {

      singupErrorsItems: (oldState, { payload }) => {
        return {
          ...oldState,
          ...payload,
        }
      }
    },
  });

export const { singupErrorsItems } = singupErrors.actions;

export const stateSingupErrors = state => state.singupErrors;

export default singupErrors.reducer;