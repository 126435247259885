import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Image, Drawer, message, Button } from 'antd';
import { useIsLoggedIn, useLandingPage, useModulesView } from '../../hooks/ReduxHooks';
import { USER_API_LOGOUT, API_GET_MEDIAS } from '../../config';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { isLogged } from '../../reducers/slices/isLoggedIn.slice';
import socket from '../../socket/Socket';
import MenuItems from '../../dynamicValues/MenuItems';
import './HeaderMenu.scss'
import BtnLanguage from '../commun/BtnLanguage';

export default function HeaderMenu() {

  const modulesView = useModulesView();

  const [messageApi, contextHolderMessage] = message.useMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { menuConnected, menuNotConnected, menu } = MenuItems();

  const { t, i18n } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedKey, setSelectedKey] = useState('home');

  const userFound = useIsLoggedIn();
  const landingPage = useLandingPage();

  const [items, setItems] = useState(menu);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1279 ? true : false);
  const [isTop, setIsTop] = useState(window.innerWidth > 599 ? false : true);




  useEffect(() => {
    setItems(menu);

  }, [i18n.language]);



  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1279) {
        setIsLargeScreen(true)
      } else {
        setIsLargeScreen(false);
      }
      if (window.innerWidth > 599) {
        setIsTop(false)
      } else {
        setIsTop(true)
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onClickMenu = (e) => {
    const selectedItem = items.find(item => item.key === e.key);

    setSelectedKey(e.key);
    setOpenMenu(false);

    if (selectedItem) {
      const { path, type } = selectedItem;

      if (type === 'link') {
        if (path === '/' && location.pathname === '/') {
          window.scrollTo({
            top: 0,
            behavior: 'smooth' // Défilement fluide
          });
        } else {
          navigate(path);
        }


      }

      if (type === 'anchor') {
        const anchorId = selectedItem.path.slice(1);
        const targetElement = document.getElementById(anchorId);

        if (targetElement) {
          const elementPosition = targetElement.getBoundingClientRect().top;
          const currentScrollPosition = window.pageYOffset;
          const offsetPosition = currentScrollPosition + elementPosition - 65;
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth' // Défilement fluide
          });
          /*   targetElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'center'
            }); */

        }


        navigate('/');
      }
      if (type === 'function' && path === 'Singout') {
        if (userFound.logged) {
          axios.post(`${USER_API_LOGOUT}`, { withCredentials: true })
            .then(response => {
              messageApi.success({ content: t('singout:successSingout'), key: 'updatable', duration: 1 });
              dispatch(isLogged({
                logged: false,
                user: null
              }));
              socket.disconnect();
              socket.connect();
              navigate('/', { replace: true });
            })
            .catch(error => {
              messageApi.error({ content: t('singout:errorSingout'), key: 'updatable', duration: 1 });
            });
        } else {
          navigate('/Singin', { replace: true });
        }
      }

    }
  };
  const onClickMenuInLine = (e) => {
    let selectedItem = null;
    if (userFound.logged) {
      selectedItem = menuConnected.find(item => item.key === e.key);
    } else {
      selectedItem = menuNotConnected.find(item => item.key === e.key);
    }
    setSelectedKey(e.key);
    setOpenMenu(false);

    if (selectedItem) {
      const { path, type } = selectedItem;

      if (type === 'link') {
        if (path === '/' && location.pathname === '/') {
          window.scrollTo({
            top: 0,
            behavior: 'smooth' // Défilement fluide
          });
        } else {
          navigate(path);
        }


      }

      if (type === 'anchor') {
        const anchorId = selectedItem.path.slice(1);
        const targetElement = document.getElementById(anchorId);

        if (targetElement) {
          const elementPosition = targetElement.getBoundingClientRect().top;
          const currentScrollPosition = window.pageYOffset;
          const offsetPosition = currentScrollPosition + elementPosition - 65;
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth' // Défilement fluide
          });
          /*   targetElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'center'
            }); */

        }


        navigate('/');
      }
      if (type === 'function' && path === 'Singout') {
        if (userFound.logged) {
          axios.post(`${USER_API_LOGOUT}`, { withCredentials: true })
            .then(response => {
              messageApi.success({ content: t('singout:successSingout'), key: 'updatable', duration: 1 });
              dispatch(isLogged({
                logged: false,
                user: null
              }));
              socket.disconnect();
              socket.connect();
              navigate('/', { replace: true });
            })
            .catch(error => {
              messageApi.error({ content: t('singout:errorSingout'), key: 'updatable', duration: 1 });
            });
        } else {
          navigate('/Singin', { replace: true });
        }
      }

    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const anchors = document.querySelectorAll('[id]');
      const scrollTop = window.scrollY + 100; // ajuster la valeur pour prendre en compte la hauteur du menu
      let newSelectedAnchor = null;

      anchors.forEach((anchor) => {
        if (anchor.offsetTop <= scrollTop && anchor.offsetTop + anchor.offsetHeight > scrollTop) {
          newSelectedAnchor = anchor.id;
        }
      });

      if (newSelectedAnchor && newSelectedAnchor !== selectedKey) {
        setSelectedKey(newSelectedAnchor);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const LogoMainDesktop = React.memo(() => {
    const logoDesktopDefault = useMemo(() => `${API_GET_MEDIAS}default/logo/logoDesktop.png`, []);
    const logoDesktopUrl = useMemo(() => landingPage.logoDesktop ? `${API_GET_MEDIAS}logo/logoDesktop/${landingPage.logoDesktop}` : logoDesktopDefault, [landingPage.logoDesktop]);

    return (

      <NavLink to='/' >
        <Image src={logoDesktopUrl} alt='Logo' preview={false} />
      </NavLink>

    );
  });


  function MainMenu({ isInLine = false }) {
    return (
      <Menu
        onClick={onClickMenu}
        selectedKeys={[selectedKey]}
        mode={isInLine ? "inline" : "horizontal"}
        items={items}
        style={!isInLine ? { display: 'flex', justifyContent: 'flex-end' } : null}
      />
    );
  }

  function MainMenuInLine({ isInLine = false }) {
    return (
      <Menu
        onClick={onClickMenuInLine}
        selectedKeys={[selectedKey]}
        mode={isInLine ? "inline" : "horizontal"}
        items={userFound.logged ? menuConnected : menuNotConnected}
        style={!isInLine ? { display: 'flex', justifyContent: 'flex-end' } : null}
      />
    );
  }

  const handleSingout = async () => {
    if (userFound.logged) {
      try {
        await axios.post(`${USER_API_LOGOUT}`, { withCredentials: true });

        dispatch(isLogged({
          logged: false,
          user: null
        }));

        socket.disconnect();
        socket.connect();

        await messageApi.success({ content: t('singout:successSingout'), key: 'updatable', duration: 1 });


        navigate('/', { replace: true });

      } catch (error) {
        messageApi.error({ content: t('singout:errorSingout'), key: 'updatable', duration: 1 });
      }
    } else {
      navigate('/Singin', { replace: true });
    }
  }

  return (
    <>
      <div className='header-container' id='home'>
        {contextHolderMessage}
        <div className='btn-menu-drawer' data-aos="zoom-in">
          <Button shape='circle' icon={<FontAwesomeIcon icon={!openMenu ? ['fas', 'bars-staggered'] : ['fas', 'xmark']} onClick={() => setOpenMenu(!openMenu)} />} />

        </div>

        <div className='header-logo-container'>
          <div className='header-logo'>
            {modulesView.logo && (<LogoMainDesktop />)}
          </div>
        </div>

        {isLargeScreen && (
          <div className='header-menu-items'>
            <MainMenu />
          </div>
        )
        }

        {userFound.logged ?
          <div className='header-auth'>

            <Button className='btn-register-logout' danger onClick={handleSingout}>
              <Trans>headermenu:logout</Trans>
            </Button>
          </div> :
          <div className='header-auth'>
            {location.pathname !== '/Singin' && (
              <Button
                className={location.pathname !== '/Singup' ? 'btn-login' : 'btn-register-logout'}
                type={location.pathname !== '/Singup' ? 'link' : 'primary'}
                onClick={() => navigate('/Singin')}>
                <Trans>headermenu:login</Trans>
              </Button>
            )}
            {location.pathname !== '/Singup' && (
              <Button className='btn-register-logout' type='primary' onClick={() => navigate('/Singup')}>
                <Trans>headermenu:register</Trans>
              </Button>
            )}
          </div>
        }
        <div className='header-config'>
          <div className='border-divid' />
          <BtnLanguage />
        </div>
      </div>
      {!isLargeScreen && (
        <Drawer
          placement={isTop ? 'top' : 'left'}
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          className='menu-drawer'
        >
          <MainMenuInLine isInLine />
        </Drawer>





      )}
    </>
    /*  <div className='he




ader-container'>
       {contextHolderMessage}
       <div className='header-logo' >  {modulesView.logo && (<LogoMainDesktop />)}</div>
       {modulesView.headerMenu && (
         <div className='header-menu-items'>
           {!isLargeScreen && (
             <div className='btn-menu' data-aos="zoom-in">
               <FontAwesomeIcon icon={['fas', 'fa-bars']} onClick={() => setOpenMenu(true)} />
             </div>
           )}
 
           {isLargeScreen && (
             <div className='header-menu-view' data-aos="zoom-in">
               <MainMenu />
             </div>
           )}
 
 
           <Drawer
             title={modulesView.logo && <LogoMainMobile />}
             placement='top'
             open={openMenu}
             onClose={() => setOpenMenu(false)}
             className='menu-drawer'
           >
             <MainMenu isInLine />
           </Drawer>
         </div>
       )}
     </div> */
  );
}
