import React, { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { useThemeApp } from '../hooks/ReduxHooks';
import { useTranslation } from 'react-i18next';

export default function MenuItems() {

  const themeApp = useThemeApp();
  const { t } = useTranslation();
  


  const menuConnected = [
    {
      label: t('headermenu:home'),
      key: 'home',
      icon: <FontAwesomeIcon icon={['fas','fa-house']} style={{ color: themeApp.iconColor }} />,
      path: '/',
      type: 'link'
    },
    {
      label: t('headermenu:presidentWord'),
      key: 'president',
      icon: <FontAwesomeIcon icon={['fas','fa-person']} style={{ color: themeApp.iconColor }} />,
      path: '#president',
      type: 'anchor'

    },
    {
      label: t('users:program.pageTitle'),
      key: 'program',
      icon: <FontAwesomeIcon icon={['fas','calendar']} style={{ color: themeApp.iconColor }} />,
      path: '#program',
      type: 'anchor'

    },
    {
      label: t('headermenu:ourTeam'),
      key: 'teams',
      icon: <FontAwesomeIcon icon={['fas', 'users-between-lines']} style={{ color: themeApp.iconColor }} />,
      path: '#teams',
      type: 'anchor'
    },
    {
      label: t('headermenu:logout'),
      key: 'login',
      icon: <FontAwesomeIcon icon={['fas' ,'fa-close']} style={{ color: themeApp.iconColor }} />,
      path: 'Singout',
      type: 'function'
    },
    {
      label: t('headermenu:contact'),
      key: 'Contact',
      icon: <FontAwesomeIcon icon={['fas', 'location-dot']} style={{ color: themeApp.iconColor }} />,
      path: '#contact',
      type: 'anchor'

    },
  ]

  const menuNotConnected = [
    {
      label: t('headermenu:home'),
      key: 'home',
      icon: <FontAwesomeIcon icon={['fas','fa-house']} style={{ color: themeApp.iconColor }} />,
      path: '/',
      type: 'link'
    },
    {
      label: t('headermenu:presidentWord'),
      key: 'president',
      icon: <FontAwesomeIcon icon={['fas','fa-person']} style={{ color: themeApp.iconColor }} />,
      path: '#president',
      type: 'anchor'

    },
    {
      label: t('users:program.pageTitle'),
      key: 'program',
      icon: <FontAwesomeIcon icon={['fas','calendar']} style={{ color: themeApp.iconColor }} />,
      path: '#program',
      type: 'anchor'

    },
    {
      label: t('headermenu:ourTeam'),
      key: 'teams',
      icon: <FontAwesomeIcon icon={['fas', 'users-between-lines']} style={{ color: themeApp.iconColor }} />,
      path: '#teams',
      type: 'anchor'
    },
    {
      label: t('headermenu:login'),
      key: 'login',
      icon: <FontAwesomeIcon icon={['fas', 'fa-key']} style={{ color: themeApp.iconColor }} />,
      path: '/Singin',
      type: 'link'
    },
    {
      label: t('headermenu:register'),
      key: 'register',
      icon: <FontAwesomeIcon icon={['fas', 'id-card']} style={{ color: themeApp.iconColor }} />,
      path: '/Singup',
      type: 'link'
    },
    {
      label: t('headermenu:contact'),
      key: 'Contact',
      icon: <FontAwesomeIcon icon={['fas', 'location-dot']} style={{ color: themeApp.iconColor }} />,
      path: '#contact',
      type: 'anchor'

    },
  ]

  const menu = [
    {
      label: t('headermenu:home'),
      key: 'home',
      icon: <FontAwesomeIcon icon={['fas','fa-house']} style={{ color: themeApp.iconColor }} />,
      path: '/',
      type: 'link'
    },
    {
      label: t('headermenu:presidentWord'),
      key: 'president',
      icon: <FontAwesomeIcon icon={['fas','fa-person']} style={{ color: themeApp.iconColor }} />,
      path: '#president',
      type: 'anchor'

    },
    {
      label: t('users:program.pageTitle'),
      key: 'program',
      icon: <FontAwesomeIcon icon={['fas','calendar']} style={{ color: themeApp.iconColor }} />,
      path: '#program',
      type: 'anchor'

    },
    {
      label: t('headermenu:ourTeam'),
      key: 'teams',
      icon: <FontAwesomeIcon icon={['fas', 'users-between-lines']} style={{ color: themeApp.iconColor }} />,
      path: '#teams',
      type: 'anchor'
    },
    {
      label: t('headermenu:contact'),
      key: 'Contact',
      icon: <FontAwesomeIcon icon={['fas', 'location-dot']} style={{ color: themeApp.iconColor }} />,
      path: '#contact',
      type: 'anchor'

    },
  ]

  return {
    menuConnected,
    menuNotConnected,
    menu
  }
}
