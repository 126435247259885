import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import AppLayout from './appLayout/AppLayout';
import SpinnerPage from './components/commun/SpinnerPage';
import RequireAuth from './validator/RequireAuth';
import RequireRole from './validator/RequireRole';


const MainView = lazy(() => import('./pageView/mainPage/MainPage'));
const PrivacyPolicyView = lazy(() => import('./pageView/cookies/PrivacyPolicyView'));
const Page403View = lazy(() => import('./pageView/resultPages/Page403View'));

const SinginView = lazy(() => import('./pageView/authentification/SingInView'));
const SingupView = lazy(() => import('./pageView/authentification/SingupView'));
const VerifyAccountView = lazy(() => import('./pageView/authentification/VerifyAccountView'));
const VerifyAccountErrorView = lazy(() => import('./pageView/authentification/VerifyAccountErrorView'));
const ForgetPasswordView = lazy(() => import('./pageView/authentification/ForgetPasswordView'));
const FPESuccessView = lazy(() => import('./pageView/authentification/FPESuccessView'));
const ResetPasswordView = lazy(() => import('./pageView/authentification/ResetPasswordView'));
const SingupViewtest = lazy(() => import('./pageView/authentification/SingupViewtest'))

const DashboardView = lazy (() => import('./pageView/controlPanel/dashboard/DashboardView'));
const SkinManagerView = lazy (() => import('./pageView/controlPanel/skin/SkinManagerView'));
const LandingPageManagerView = lazy(() => import('./pageView/controlPanel/landingPage/LandingPageManagerView'));
const LogoManagerView = lazy(() => import('./pageView/controlPanel/logo/LogoManagerView'));
const CarouselManagerView = lazy(() => import('./pageView/controlPanel/carousel/CarouselManagerView'));
const ContactManagerView = lazy(() => import('./pageView/controlPanel/contact/ContactManagerView'));
const SocialMediasManagerView = lazy(() => import('./pageView/controlPanel/socialMedias/SocialMediasManagerView'));
const ChairmanMessageManagerView = lazy(() => import('./pageView/controlPanel/chairman/ChairmanMessageManagerView'));
const TeamCatManagerView = lazy(() => import('./pageView/controlPanel/team/teamCat/TeamCatManagerView'));
const TeamManagerView = lazy(() => import('./pageView/controlPanel/team/teamMember/TeamManagerView'));
const SponsorsCatManagerView = lazy(() => import('./pageView/controlPanel/sponsors/sponsorCat/SponsorsCatManagerView'));
const SponsorsManagerView = lazy(() => import('./pageView/controlPanel/sponsors/sponsors/SponsorsManagerView'));
const InfosBarManagerView = lazy(() => import('./pageView/controlPanel/infosBar/InfosBarManagerView'));
const ThemesManagerView = lazy(() => import('./pageView/controlPanel/themes/ThemesManagerView'));
const PartnersCatManagerView = lazy(() => import('./pageView/controlPanel/partners/partnersCat/PartnersCatManagerView'));
const PartnersManagerView = lazy(() => import('./pageView/controlPanel/partners/partners/PartnersManagerView'));
const CounterManagerView = lazy(() => import('./pageView/controlPanel/counter/CounterManagerView'));
const ModulesManagerView = lazy(() => import('./pageView/controlPanel/modules/ModulesManagerView'));
const ProgramManagerView = lazy(() => import('./pageView/controlPanel/program/ProgramManagerView'));

const PlateformManagerView = lazy(() => import('./pageView/plateform/PlateformManagerView'));
const TranslatorEditorView = lazy(() => import('./pageView/plateform/translationEditor/TranslatorEditorView'));
const LanguesManagerView = lazy(() => import('./pageView/plateform/langues/LanguesManagerView'));

const SingupRolesManagerView = lazy(() => import('./pageView/plateform/singupRoles/SingupRolesManagerView'));
const SingupHonorificManagerView = lazy(() => import('./pageView/plateform/singupHonorific/SingupHonorificManagerView'));
const SingupProClassManagerView = lazy(() => import('./pageView/plateform/singupProClass/SingupProClassManagerView'));
const SingupConfigManagerView = lazy(() => import('./pageView/plateform/singupConfig/SingupConfigManagerView'));

const PackCatManagerView = lazy(() => import('./pageView/plateform/packs/packsCat/PacksCatManagerView'));
const PackManagerView = lazy(() => import('./pageView/plateform/packs/packs/PacksManagerView'));

const PaymentListManagerView = lazy(() => import('./pageView/plateform/payment/PaymentListManagerView'));

const UsersManagerView = lazy(() => import('./pageView/usersManager/UsersManagerView'));


const PayementPvView = lazy(() => import('./pageView/users/payementPv/PayementPvView'));
const ProgramView = lazy(() => import('./pageView/users/program/ProgramView'));


const Test = lazy(() => import('./pageView/test/Test'));
export default function App() {


  return (

        <Routes >
          <Route path='/' element={<AppLayout />}>
            <Route path='/' element={<Suspense fallback={<SpinnerPage />}> <MainView /></Suspense>} />
            <Route path='/PrivacyPolicy' element={<Suspense fallback={<SpinnerPage />}> <PrivacyPolicyView /></Suspense>} />
            <Route path='/Unauthorized' element={<Suspense fallback={<SpinnerPage />}> <Page403View /></Suspense>} />

            <Route path='/Singup' element={<Suspense fallback={<SpinnerPage />}> <SingupView /></Suspense>} />
            <Route path='/Singuptest' element={<Suspense fallback={<SpinnerPage />}> <SingupViewtest /></Suspense>} />
            <Route path='/Singin' element={<Suspense fallback={<SpinnerPage />}> <SinginView /></Suspense>} />
            <Route path='/VerifyAccount' element={<Suspense fallback={<SpinnerPage />}> <VerifyAccountView /></Suspense>} />
            <Route path='/VerifyAccountError' element={<Suspense fallback={<SpinnerPage />}> <VerifyAccountErrorView /></Suspense>} />
            <Route path='/ForgetPassword' element={<Suspense fallback={<SpinnerPage />}> <ForgetPasswordView /></Suspense>} />
            <Route path='/FPESuccess' element={<Suspense fallback={<SpinnerPage />}> <FPESuccessView /></Suspense>} />
            <Route path='/ResetPassword' element={<Suspense fallback={<SpinnerPage />}> <ResetPasswordView /></Suspense>} />

            <Route element={<RequireAuth />}>

            <Route path='/PayementPv' element={<Suspense fallback={<SpinnerPage />}> <PayementPvView /></Suspense>} />
            <Route path='/Program' element={<Suspense fallback={<SpinnerPage />}> <ProgramView /></Suspense>} />

            <Route element={<RequireRole allowedRoles={['SuperAdmin']} />}>

                <Route path="/CPanel" element={<Suspense fallback={<SpinnerPage />}><DashboardView /></Suspense>} />
                <Route path="/SkinManager" element={<Suspense fallback={<SpinnerPage />}><SkinManagerView /></Suspense>} />
                <Route path="/LandingPageManager" element={<Suspense fallback={<SpinnerPage />}><LandingPageManagerView /></Suspense>} />
                <Route path="/LogoManager" element={<Suspense fallback={<SpinnerPage />}><LogoManagerView /></Suspense>} />
                <Route path="/CarouselManager" element={<Suspense fallback={<SpinnerPage />}><CarouselManagerView /></Suspense>} />
                <Route path="/ContactManager" element={<Suspense fallback={<SpinnerPage />}><ContactManagerView /></Suspense>} />
                <Route path="/SocialMediaManager" element={<Suspense fallback={<SpinnerPage />}><SocialMediasManagerView /></Suspense>} />
                <Route path="/ChairmanWord" element={<Suspense fallback={<SpinnerPage />}><ChairmanMessageManagerView /></Suspense>} />
                <Route path="/TeamCatManager" element={<Suspense fallback={<SpinnerPage />}><TeamCatManagerView /></Suspense>} />
                <Route path="/TeamsManager" element={<Suspense fallback={<SpinnerPage />}><TeamManagerView /></Suspense>} />
                <Route path="/SponsorsCatManager" element={<Suspense fallback={<SpinnerPage />}><SponsorsCatManagerView /></Suspense>} />
                <Route path="/SponsorsManager" element={<Suspense fallback={<SpinnerPage />}><SponsorsManagerView /></Suspense>} />
                <Route path="/InfosBarManager" element={<Suspense fallback={<SpinnerPage />}><InfosBarManagerView /></Suspense>} />
                <Route path="/ThemesManager" element={<Suspense fallback={<SpinnerPage />}><ThemesManagerView /></Suspense>} />
                <Route path="/PartnersCatManager" element={<Suspense fallback={<SpinnerPage />}><PartnersCatManagerView /></Suspense>} />
                <Route path="/PartnersManager" element={<Suspense fallback={<SpinnerPage />}><PartnersManagerView /></Suspense>} />
                <Route path="/Counter" element={<Suspense fallback={<SpinnerPage />}><CounterManagerView /></Suspense>} />
                <Route path="/ModulesViewManager" element={<Suspense fallback={<SpinnerPage />}><ModulesManagerView /></Suspense>} />
                <Route path="/ProgramManager" element={<Suspense fallback={<SpinnerPage />}><ProgramManagerView /></Suspense>} />


                <Route path="/PlateformManager" element={<Suspense fallback={<SpinnerPage />}><PlateformManagerView /></Suspense>} />
                <Route path="/TranslatorEditor" element={<Suspense fallback={<SpinnerPage />}><TranslatorEditorView /></Suspense>} />
                <Route path="/LanguageManager" element={<Suspense fallback={<SpinnerPage />}><LanguesManagerView /></Suspense>} />

                <Route path="/singupRoles" element={<Suspense fallback={<SpinnerPage />}><SingupRolesManagerView /></Suspense>} />
                <Route path="/singupHonorific" element={<Suspense fallback={<SpinnerPage />}><SingupHonorificManagerView /></Suspense>} />
                <Route path="/singupProClass" element={<Suspense fallback={<SpinnerPage />}><SingupProClassManagerView /></Suspense>} />
                <Route path="/SingupConfig" element={<Suspense fallback={<SpinnerPage />}><SingupConfigManagerView /></Suspense>} />


                <Route path="/PacksCatManager" element={<Suspense fallback={<SpinnerPage />}><PackCatManagerView /></Suspense>} />
                <Route path="/PacksManager" element={<Suspense fallback={<SpinnerPage />}><PackManagerView /></Suspense>} />

                <Route path="/PaymentManager" element={<Suspense fallback={<SpinnerPage />}><PaymentListManagerView /></Suspense>} />

            </Route>
            <Route element={<RequireRole allowedRoles={['SuperAdmin', 'Admin']} />}>
                <Route path="/UsersManager" element={<Suspense fallback={<SpinnerPage />}><UsersManagerView /></Suspense>} />
            </Route>


{/*             <Route path="/test" element={<Suspense fallback={<SpinnerPage />}><Test /></Suspense>} /> */}
            </Route>
          </Route>
        </Routes>
  )
}
