import { createSlice } from '@reduxjs/toolkit';


let initialState = {

}



export const themeApp = createSlice(
  {
    name: 'themeApp',
    initialState,
    reducers: {

      themeItems: (oldState, { payload }) => {
        return {
          ...oldState,
          ...payload,
        }
      }
    },
  });

export const { themeItems } = themeApp.actions;

export const stateThemeApp = state => state.themeApp;

export default themeApp.reducer;