import { createSlice } from '@reduxjs/toolkit';

let initialState = {
}

export const landingPage = createSlice(
  {
    name: 'landingPage',
    initialState,
    reducers: {

      landingPageItems: (oldState, { payload }) => {
        return {
          ...oldState,
          ...payload,
        }
      }
    },
  });

export const { landingPageItems } = landingPage.actions;

export const stateLandingPage = state => state.landingPage;

export default landingPage.reducer;