import { createSlice } from '@reduxjs/toolkit';


let initialState = {
}
export const plateform = createSlice(
  {
    name: 'plateform',
    initialState,
    reducers: {

      plateformItems: (oldState, { payload }) => {
        return {
          ...oldState,
          ...payload,
        }
      }
    },
  });

export const { plateformItems } = plateform.actions;

export const statePlateform = state => state.plateform;

export default plateform.reducer;