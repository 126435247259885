import { combineReducers } from 'redux';

import isLoggedInSlice from './slices/isLoggedIn.slice';
import themeAppSlice from './slices/themeApp.slice';
import modulesViewSlice from './slices/modulesView.slice';
import landingPageSlice from './slices/landingPage.slice';
import singupConfigSlice from './slices/singupConfig.slice';
import singupErrorsSlice from './slices/singupErrors.slice';
import carouselSlice from './slices/carousel.slice';
import plateformSlice from './slices/plateform.slice';
import nbreUsersSlice from './slices/nbreUsers.slice';
import nbreAbstractsSlice from './slices/nbreAbstracts.slice';
import programSlice from './slices/program.slice';

const rootReducer = combineReducers({
    isLoggedIn : isLoggedInSlice,
    themeApp : themeAppSlice,
    modulesView : modulesViewSlice,
    landingPage : landingPageSlice,
    singupConfig : singupConfigSlice,
    singupErrors : singupErrorsSlice,
    carousel : carouselSlice,
    plateform : plateformSlice,
    nbreUsers : nbreUsersSlice,
    nbreAbstracts : nbreAbstractsSlice,
    program : programSlice
});

export default rootReducer;
