import { createSlice } from '@reduxjs/toolkit';


let initialState = {}

export const nbreUsers = createSlice(
  {
    name: 'nbreUsers',
    initialState,
    reducers: {

      nbreUsersItems: (state, { payload }) => {
        return payload;
      }
    },
  });

export const { nbreUsersItems } = nbreUsers.actions;

export const stateNbreUsers = state => state.nbreUsers;

export default nbreUsers.reducer;