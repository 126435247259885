import { createSlice } from '@reduxjs/toolkit';


let initialState = {}

export const carousel = createSlice(
  {
    name: 'carousel',
    initialState,
    reducers: {

      carouselItems: (state, { payload }) => {
        return payload;
      }
    },
  });

export const { carouselItems } = carousel.actions;

export const stateCarousel = state => state.carousel;

export default carousel.reducer;