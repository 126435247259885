import { useSelector } from "react-redux";
import { stateThemeApp } from "../reducers/slices/themeApp.slice";
import { stateModulesView } from "../reducers/slices/modulesView.slice";
import { stateLandingPage } from "../reducers/slices/landingPage.slice";
import { stateisLoggedIn } from "../reducers/slices/isLoggedIn.slice";
import { stateSingupConfig } from "../reducers/slices/singupConfig.slice";
import { stateCarousel } from "../reducers/slices/carousel.slice";
import { statePlateform } from "../reducers/slices/plateform.slice";
import { stateSingupErrors } from "../reducers/slices/singupErrors.slice";
import { stateNbreUsers } from "../reducers/slices/nbreUsers.slice";
import { stateNbreAbstracts } from "../reducers/slices/nbreAbstracts.slice";
import { stateProgram } from "../reducers/slices/program.slice";

export const useThemeApp = () => {
    return useSelector(stateThemeApp);
}

export const useModulesView = () => {
    return useSelector(stateModulesView);
}

export const useLandingPage = () => {
    return useSelector(stateLandingPage);
}

export const useIsLoggedIn = () => {
    return useSelector(stateisLoggedIn);
}

export const useSingupConfig = () => {
    return useSelector(stateSingupConfig);
}

export const useCarouselItems = () => {
    return useSelector(stateCarousel);
}

export const usePlateform = () => {
    return useSelector(statePlateform);
}

export const useSingupErrors = () => {
    return useSelector(stateSingupErrors);
}

export const useNbreUsers = () => {
    return useSelector(stateNbreUsers);
}

export const useNbreAbstracts = () => {
    return useSelector(stateNbreAbstracts);
}

export const useProgram = () => {
    return useSelector(stateProgram);
}