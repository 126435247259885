import { createSlice } from '@reduxjs/toolkit';


let initialState = {
  logged: false,
  user: null
}


export const isLoggedIn = createSlice(
  {
    name: 'isLoggedIn',
    initialState,
    reducers: {

      isLogged: (oldState, { payload }) => {
        return {
          ...oldState,
          ...payload,
        }
      }
    },
  });

export const { isLogged } = isLoggedIn.actions;

export const stateisLoggedIn = state => state.isLoggedIn;

export default isLoggedIn.reducer;
