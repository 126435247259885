import { createSlice } from '@reduxjs/toolkit';


let initialState = {}

export const nbreAbstracts = createSlice(
  {
    name: 'nbreAbstracts',
    initialState,
    reducers: {

      nbreAbstractsItems: (state, { payload }) => {
        return payload;
      }
    },
  });

export const { nbreAbstractsItems } = nbreAbstracts.actions;

export const stateNbreAbstracts = state => state.nbreAbstracts;

export default nbreAbstracts.reducer;