import { createSlice } from '@reduxjs/toolkit';


let initialState = {
  infosBar: false,
  logo: false,
  headerMenu: false,
  carousel: false,
  congresTheme: false,
  chairmanMessage: false,
  teams: false,
  contact: false,
  socialMedias: false
}



export const modulesView = createSlice(
  {
    name: 'modulesView',
    initialState,
    reducers: {

      modulesViewItems: (oldState, { payload }) => {
        return {
          ...oldState,
          ...payload,
        }
      }
    },
  });

export const { modulesViewItems } = modulesView.actions;

export const stateModulesView = state => state.modulesView;

export default modulesView.reducer;