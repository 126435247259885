import { io } from "socket.io-client";
import { SOCKET_URL } from '../config';

const socket = io({
    path: `${SOCKET_URL}`,
    withCredentials: true,
    autoConnect: false,
}
);

export default socket;