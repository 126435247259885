import React from 'react';
import { ConfigProvider } from 'antd';
import { theme } from 'antd';
import frFR from 'antd/locale/fr_FR';

import { useThemeApp } from '../../hooks/ReduxHooks';

const ThemeApp = ({ children }) => {

  const themeApp = useThemeApp();

  const dynamicTheme = {
    algorithm: theme.defaultAlgorithm,
    components: {
     
    },
    token: {
      fontFamily : themeApp.fontFamily || 'REM', 
      colorPrimary: themeApp.primaryColor ||  '#03ACF2',
      colorLink : themeApp.linkColor || "#03ACF2",
      colorInfo : themeApp.infoColor || "#03ACF2",
      colorSuccess : themeApp.successColor || "#52c41a",
      colorWarning : themeApp.warningColor || "#faad14",
      colorError : themeApp.errorColor || "#ff4d4f",
      colorTextBase : themeApp.textBaseColor || "#000"
    }
  };

  return (
   
    <ConfigProvider theme={dynamicTheme} locale={frFR} >
      {children}
    </ConfigProvider>
   
  );
};

export default ThemeApp;