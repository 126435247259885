import React, { useEffect, useState } from 'react';

import { Layout } from 'antd';

import { Outlet } from 'react-router-dom';

import { useThemeApp, useModulesView, useLandingPage } from '../hooks/ReduxHooks';
import InfosBar from '../components/infosBar/InfosBar';
import HeaderMenu from '../components/headerMenu/HeaderMenu';

export default function AppLayout() {

    const themeApp = useThemeApp();
    const modulesView = useModulesView();
    const landingPage = useLandingPage();

    const { Header, Content, Footer } = Layout

    const getCurrentYear = () => new Date().getFullYear();
    const currentYear = getCurrentYear();

    const [scrollInfoTop, setScrollInfoTop] = useState(0);

    const textsInfosBar = landingPage.infosBar ? landingPage.infosBar.filter(items => items.infoActive === true) : null;



    const headerInfosHeight = getComputedStyle(document.documentElement).getPropertyValue('--headerInfosHeight');
    const marginMenuWithinfoBar = getComputedStyle(document.documentElement).getPropertyValue('--marginMenuWithinfoBar');
    const marginInfosBar = getComputedStyle(document.documentElement).getPropertyValue('--marginInfosBar');
    const marginMenu = getComputedStyle(document.documentElement).getPropertyValue('--marginMenu');
    const marginWithoutMenuInfoBar = getComputedStyle(document.documentElement).getPropertyValue('--marginWithoutMenuInfoBar');
    const firstHeaderHeight = modulesView.infosBar && landingPage.infosBar && textsInfosBar && textsInfosBar.length > 0 ? `${parseInt(headerInfosHeight, 10)}` : 0;
    document.documentElement.style.setProperty('--borderContainerColor', themeApp.primaryColor);

        useEffect(() => {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, []);

    const handleScroll = () => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        setScrollInfoTop(scrollTop);
    };

    return (
        <Layout>
            {modulesView.infosBar && landingPage.infosBar && textsInfosBar && textsInfosBar.length > 0 && (
                (
                    <Header
                        className='header-infos'
                        style={{ marginTop: scrollInfoTop < 200 ? 0 : `-${parseInt(headerInfosHeight, 10)}px`, backgroundColor: themeApp.infosBarBkColor }}
                    >
                        <InfosBar />
                    </Header>
                )
            )}
            {(modulesView.headerMenu || modulesView.logo) && (
                <Header
                    className="header-menu"
                    style={{ marginTop: scrollInfoTop < 200 ? `${firstHeaderHeight}px` : 0 }}
                >
                    <HeaderMenu />
                </Header>
            )}

            <Content
                style={{
                    paddingTop: (() => {
                        if (
                            (!modulesView.logo && !modulesView.headerMenu) &&
                            (!modulesView.infosBar || !landingPage.infosBar || !textsInfosBar || textsInfosBar.length === 0)
                        ) {
                            return marginWithoutMenuInfoBar;
                        } else if (
                            (modulesView.infosBar && landingPage.infosBar && textsInfosBar && textsInfosBar.length > 0) &&
                            (modulesView.headerMenu || modulesView.logo)
                        ) {
                            return marginMenuWithinfoBar;
                        } else if (
                            (modulesView.infosBar && landingPage.infosBar && textsInfosBar && textsInfosBar.length > 0) &&
                            (!modulesView.logo && !modulesView.headerMenu)
                        ) {
                            return marginInfosBar;
                        } else if (
                            (!modulesView.infosBar || !landingPage.infosBar || !textsInfosBar || textsInfosBar.length === 0) &&
                            (modulesView.headerMenu || modulesView.logo)
                        ) {
                            return marginMenu;
                        } else {
                            return 0; 
                        }
                    })(),
                    paddingBottom: modulesView.footer ? 'var(--footerHeight)' : 0
                }}
            >

                <Outlet />

            </Content>

            {modulesView.footer &&
                <Footer >
                    &copy; Copyright Medical Events {currentYear} . All Rights Reserved. Designed by Ah Mo
                </Footer>
            }


         
        </Layout>
    )
}
