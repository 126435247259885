export const SOCKET_URL = `/sio`;
export const API_GET_MEDIAS = `medias-mep/`;
export const API_GET_LANGUES = `mep-languages/`;
export const COOKIES_NAME = `MEP_COOKIES`;

export const API_GET_URL_FETCH = `api/testmodule/getURLFetch`;
export const API_GET_LAB_LIST = `api/testmodule/getLabList`;
export const API_GET_LAB_CODES = `api/testmodule/getLabCodes`;

export const TRANSLATION_API_GETNS = `api/translations/:lang/:namespace`;

export const FIREBASE_APIKEY = `AIzaSyDsK6tgg9D68UMv8wlepsRkuzG-31hn2V8`;
export const FIREBASE_AUTHDOMAIN = `new-medical-app-64f86.firebaseapp.com`;
export const FIREBASE_PROJECTID = `new-medical-app-64f86`;
export const FIREBASE_STORAGEBUCKET = `new-medical-app-64f86.appspot.com`;
export const FIREBASE_MESSAGINGSENDERID = `695506524445`;
export const FIREBASE_APPID = `1:695506524445:web:2cf10c61ecb7f08683a9f4`;
export const FIREBASE_MEASUREMENTID = `G-8TJHX16X3W`;
export const FIREBASE_VAPID_KEY = `BLSawg9ni32uRl3zlM9gYKzfdsqbZu4KN78AzjnXxj6g_UWEy5kYyQ5laOcQyDGTUmX2XP5yOGvViIRRltoiMZg`;

export const SUPERADMIN_API_GETSERVERDATA = `api/superadmin/getServerData`;
export const SUPERADMIN_API_SETTHEME = `api/superadmin/setSkin`;
export const SUPERADMIN_API_SETLOGO = `api/superadmin/setLogo`;

export const SUPERADMIN_API_SETPICTURECAROUSEL = `api/superadmin/setNewCarouselItem`;
export const SUPERADMIN_API_GETALLCAROUSEL = `api/superadmin/getAllCarousel`;
export const SUPERADMIN_API_CHANGEETATELEMENTCAROUSEL = 'api/superadmin/changeEtatElementCarousel'
export const SUPERADMIN_API_DELETEELEMENTCAROUSEL = 'api/superadmin/deleteElementCarousel'
export const SUPERADMIN_API_UPDATECAROUSEL = 'api/superadmin/setUpdateCarouselItem'

export const SUPERADMIN_API_SETCONTACTINFOS = 'api/superadmin/setContactInfos';

export const SUPERADMIN_API_SETSOCIALMEDIAS = 'api/superadmin/setSocialMedias';

export const SUPERADMIN_API_SETTEXTPRESIDENT = `api/superadmin/setTextPresident`;
export const SUPERADMIN_API_REMOVETEXTPRESIDENT = `api/superadmin/removeTextPresident`;

export const SUPERADMIN_API_STETEAMCAT = 'api/superadmin/setTeamCat';
export const SUPERADMIN_API_SETTEAM = 'api/superadmin/setTeam';

export const SUPERADMIN_API_STESPONSORSCAT = 'api/superadmin/setSponsorsCat';
export const SUPERADMIN_API_STESPONSORS = 'api/superadmin/setSponsors';

export const SUPERADMIN_API_STEPARTNERSCAT = 'api/superadmin/setPartnersCat';
export const SUPERADMIN_API_STEPARTNERS = 'api/superadmin/setPartners';

export const SUPERADMIN_API_SETINFOSBAR = 'api/superadmin/setInfosBar';

export const SUPERADMIN_API_SETTHEMES = 'api/superadmin/setThemes';

export const SUPERADMIN_API_SETCOUNTER = 'api/superadmin/setCounter';

export const SUPERADMIN_API_SETMODULESVIEW = 'api/superadmin/setModulesView';

export const SUPERADMIN_API_SETPROGRAM = 'api/superadmin/setProgram';

export const SUPERADMIN_API_SETLANGUES = `api/superadmin/setLangues`;


export const SUPERADMIN_API_SETSINGUPROLES = `api/superadmin/setSingupRoles`;
export const SUPERADMIN_API_SETSINGUPHONORIFIC = `api/superadmin/setSingupHonorific`;
export const SUPERADMIN_API_SETSINGUPPROCLASS = `api/superadmin/setSingupProClass`;
export const SUPERADMIN_API_SETSINGUPCONFIG = `api/superadmin/setSingupConfig`;
export const SUPERADMIN_API_SETPACKCAT = 'api/superadmin/setPackCat';
export const SUPERADMIN_API_SETPACK = 'api/superadmin/setPack';
export const SUPERADMIN_API_SETPAYMENTLIST = 'api/superadmin/setPaymentList';

export const API_ADMIN_GET_ALL_USERS = 'api/admin/getAllUsers';
export const API_ADMIN_CHANGE_ACCOUNT_TYPE='api/admin/changeAccountType';
export const API_ADMIN_CHANGE_ACCOUNT_VERIFIED='api/admin/changeAccountVerified';
export const API_ADMIN_CHANGE_ACCOUNT_PAYEMENT='api/admin/changeAccountPayement';



export const USER_API_GET_PAYMENTS_INFOS = `api/user/getPaymentsInfos`;
export const API_USER_FILEPV = 'api/user/filePv';


export const USER_FIREBASE_SAVE_TOKEN = `api/notification/saveToken`;
export const USER_FIREBASE_FIND_TOKEN = `api/notification/findToken`;

export const USER_API_GET_SITEAPP_CONFIG = `api/user/getSiteAppconfig`;
export const USER_API_SINGIN = `api/auth/singin`;
export const USER_API_SINGUP = `api/auth/singup`;
export const USER_API_LOGOUT = `api/auth/logout`;
export const USER_API_VERIFY_MAILINDB = `api/auth/verifyMailInDb`;
export const USER_API_VERIFY_ACCOUNT = `api/auth/verifyAccount`;
export const USER_API_FORGOT_PASSWORD = 'api/auth/forgotPassword';
export const USER_API_VERIFY_TOKEN_RESET_PASSWORD = `api/auth/verifytokenResetPassword`;
export const USER_API_RESET_PASSWORD = `api/auth/resetPassword`;



export const USER_API_GET_GMAPS_ACCESS = `api/user/getGmapsAccess`;

export const API_USER_GETCAROUSELELEMENTS = 'api/user/getCarouselElements'
