import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, FloatButton } from 'antd'
import React from 'react'
//import languageList from '../../languages.json';
import { useTranslation } from 'react-i18next';
import { usePlateform, useThemeApp } from '../../hooks/ReduxHooks';


export default function BtnLanguage() {
    const { i18n } = useTranslation();
    const themeApp = useThemeApp();


    const languageFlags = {
        fr: { name: 'Français', flag: 'https://flagcdn.com/fr.svg' },  // France
        en: { name: 'English', flag: 'https://flagcdn.com/gb.svg' },   // UK (English)
        ar: { name: 'العربية', flag: 'https://flagcdn.com/tn.svg' },   // Tunisie (Arabe)
        es: { name: 'Español', flag: 'https://flagcdn.com/es.svg' },   // Espagne (Espagnol)
        de: { name: 'Deutsch', flag: 'https://flagcdn.com/de.svg' },   // Allemagne (Allemand)
        it: { name: 'Italiano', flag: 'https://flagcdn.com/it.svg' },  // Italie (Italien)
        pt: { name: 'Português', flag: 'https://flagcdn.com/pt.svg' }, // Portugal (Portugais)
        zh: { name: '中文', flag: 'https://flagcdn.com/cn.svg' },       // Chine (Chinois)
        ru: { name: 'Русский', flag: 'https://flagcdn.com/ru.svg' }    // Russie (Russe)
    };


    const plateform = usePlateform()

    const languageList = plateform.langues;

    const changeLanguage = async (lng) => {
        localStorage.setItem('i18nextLng', lng);
        await i18n.changeLanguage(lng);
        //i18n.languages = [lng];
        await i18n.reloadResources(lng);
        
        //  window.location.reload();

    };


    const items = languageList.map((lng, idx) => {
        const languageKey = lng.slice(0, 2);  
        const language = languageFlags[languageKey] || { name: lng, flag: '' }; 
    
        return {
            key: idx,
            label: (
                <div
                    style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        width :'100%',
                        minWidth : '100%',
                        cursor: 'pointer', 
                        paddingBottom: idx === languageList.length - 1 ? '0px' : '8px',
                        color: i18n.language === lng && themeApp.primaryColor,
                        
                    }}
                    onClick={() => changeLanguage(lng)}
                >
                    {language.flag && (
                        <img
                            src={language.flag}
                            alt={`${language.name} flag`}
                            style={{ width: '20px', height: '20px', marginRight: '8px' }}
                        />
                    )}
                    <span style={{ fontSize: '16px', fontWeight: '600' }}>
                        {language.name}
                    </span>
                </div>
            ),
        };
    });
    

    return (
        <Dropdown menu={{ items }} placement="bottomRight" arrow>
            <Button shape='circle' icon={<FontAwesomeIcon icon={['fas', 'gear']} />} />
        </Dropdown>
    )
}
