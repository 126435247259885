import { createSlice } from '@reduxjs/toolkit';


let initialState = {

}



export const singupConfig = createSlice(
  {
    name: 'singupConfig',
    initialState,
    reducers: {

      singupConfigItems: (oldState, { payload }) => {
        return {
          ...oldState,
          ...payload,
        }
      }
    },
  });

export const { singupConfigItems } = singupConfig.actions;

export const stateSingupConfig = state => state.singupConfig;

export default singupConfig.reducer;