import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './i18n'; // Initialisation de i18n
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { AxiosInterceptor } from './axiosInterceptor/AxiosInterceptor';
import SocketInterceptor from './socket/SocketInterceptor';
import NotificationProvider from './notification/NotificationProvider';
import ThemeApp from './assets/themes/ThemeApp';
import ParamsApp from './assets/themes/ParamsApp';
import { initI18n } from './i18n';
import SpinnerPage from './components/commun/SpinnerPage';

// Initialisation des animations
AOS.init();

library.add(fab, fas, far);

const root = ReactDOM.createRoot(document.getElementById('root'));

const RootComponent = () => {
  const [i18nInitialized, setI18nInitialized] = useState(false);

  // Initialiser i18n avant de rendre l'application
  useEffect(() => {
    const initializeI18n = async () => {
      await initI18n();
      setI18nInitialized(true);
    };

    initializeI18n();
  }, []);

  if (!i18nInitialized) {
    return <SpinnerPage />;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AxiosInterceptor>
            <SocketInterceptor>
              <ParamsApp>
                <ThemeApp>
                  <NotificationProvider>
                    <Routes>
                      <Route path="/*" element={<App />} />
                    </Routes>
                  </NotificationProvider>
                </ThemeApp>
              </ParamsApp>
            </SocketInterceptor>
          </AxiosInterceptor>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

root.render(<RootComponent />);

// Pour mesurer les performances de l'application
reportWebVitals();
