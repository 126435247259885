import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

import { useIsLoggedIn } from "../hooks/ReduxHooks";
const RequireRole = (props) => {
    const userFound = useIsLoggedIn();
    const location = useLocation();

    // Vérifiez si l'utilisateur a au moins un rôle autorisé
    const hasAllowedRole = Array.isArray(userFound.user.roles) 
        ? userFound.user.roles.some(role => props.allowedRoles.includes(role)) 
        : props.allowedRoles.includes(userFound.user.roles);

    return (
        !userFound.logged ? (
            <Navigate to="/Singin" state={{ from: location }} replace />
        ) : hasAllowedRole ? (
            <Outlet />
        ) : (
            <Navigate to="/unauthorized" state={{ from: location }} replace />
        )
    );
};

export default RequireRole;
