import { createSlice } from '@reduxjs/toolkit';


let initialState = {}

export const program = createSlice(
  {
    name: 'program',
    initialState,
    reducers: {

      programItems: (state, { payload }) => {
        return payload;
      }
    },
  });

export const { programItems } = program.actions;

export const stateProgram = state => state.program;

export default program.reducer;